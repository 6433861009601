import "./App.css";
import React from "react";
import LoginPage from "./pages/LoginPage";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import MenuPage from "./pages/MenuPage";


function App() {
    const router = createBrowserRouter([
        {
            path: "/",
            element: <LoginPage />,
        },
        {
            path: "/index",
            element: <MenuPage />,
        },
    ]);
    return (
        <div className="App">
            <RouterProvider router={router} />
        </div>
    );
}

export default App;
