import React, { useState } from "react";
import {
    Container,
    Box,
    Typography,
    TextField,
    Button,
    FormControl,
    InputLabel,
    InputAdornment,
    IconButton,
    OutlinedInput,
    FormHelperText,
    CircularProgress,
} from "@mui/material";
import { Icon } from "@iconify/react";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const LoginPage: React.FC = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [values, setValues] = useState({
        email: "",
        password: "",
    });
    const [errors, setErrors] = useState({
        email: "",
        password: "",
        login: "",
    });
    const { login, loading } = useAuth();
    const navigate = useNavigate();
    const validate = () => {
        let tempErrors = { email: "", password: "", login: "" };
        if (!values.email) {
            tempErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(values.email)) {
            tempErrors.email = "Email is not valid";
        }
        if (!values.password) {
            tempErrors.password = "Password is required";
        } else if (values.password.length < 6) {
            tempErrors.password = "Password must be at least 6 characters";
        }
        setErrors(tempErrors);
        return Object.values(tempErrors).every((x) => x === "");
    };

    const handleChange =
        (prop: keyof typeof values) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({ ...values, [prop]: event.target.value });
        };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        console.log("submitted");
        if (validate()) {
            console.log("login started");
            const res = await login(values.email, values.password);
            console.log(res + " res");
            if (!res) {
                setErrors({
                    ...errors,
                    login: "Email or password is incorrect",
                });
                console.log("Email or password is incorrect");
                return;
            }
            navigate("/index");
            console.log("Email:", values.email);
            console.log("Password:", values.password);
        } else {
            console.log("validation error");
        }
    };

    return (
        <Container maxWidth="md">
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography component="h1" variant="h4" gutterBottom>
                    Robust Digital
                </Typography>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                    sx={{ mt: 1 }}
                >
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={values.email}
                        onChange={handleChange("email")}
                        error={!!errors.email}
                        helperText={errors.email}
                    />
                    <FormControl
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        error={!!errors.password}
                    >
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <OutlinedInput
                            id="password"
                            type={showPassword ? "text" : "password"}
                            value={values.password}
                            onChange={handleChange("password")}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? (
                                            <Icon icon="streamline:visible-solid" />
                                        ) : (
                                            <Icon icon="streamline:invisible-1-solid" />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                        />
                        {errors.password && (
                            <FormHelperText>{errors.password}</FormHelperText>
                        )}

                        {loading && <CircularProgress />}
                        {errors.login && (
                            <FormHelperText sx={{ color: "red" }}>
                                {errors.login}
                            </FormHelperText>
                        )}
                    </FormControl>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign In
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default LoginPage;
