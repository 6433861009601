import { Modal, Box, Typography, Button } from "@mui/material";
import React from "react";

export interface ModalDetails {
    open: boolean;
    snapchat: string;
    clickflare: string;
    _id: string;
}

const DeleteConfirmationModal = ({
    onClose,
    onConfirm,
    details,
}: {
    onClose: () => void;
    onConfirm: () => void;
    details: ModalDetails;
}) => {
    return (
        <Modal
            open={details.open}
            onClose={onClose}
            aria-labelledby="delete-confirmation-title"
            aria-describedby="delete-confirmation-description"
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography
                    id="delete-confirmation-title"
                    variant="h6"
                    component="h2"
                >
                    Confirm Deletion
                </Typography>
                <Typography id="delete-confirmation-description" sx={{ mt: 2 }}>
                    Are you sure you want to delete the record with the
                    following IDs?
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    <strong>Snapchat Campaign ID:</strong> {details.snapchat}
                </Typography>
                <Typography sx={{ mt: 1 }}>
                    <strong>Clickflare ID:</strong> {details.clickflare}
                </Typography>
                <Box
                    sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}
                >
                    <Button onClick={onClose} sx={{ mr: 2 }}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={onConfirm}
                    >
                        Delete
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};
export default DeleteConfirmationModal;
