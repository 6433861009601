import { createSlice } from "@reduxjs/toolkit";
import { AuthData } from "../../types";

const initialState: AuthData = {
    user: null,
    loading: false,
    token: null,
};
const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        loginStart: (state) => {
            state.loading = true;
        },
        loginSuccess: (state, action) => {
            state.user = action.payload.user;
            state.token = action.payload.token;
            state.loading = false;
        },
        loginFailure: (state) => {
            state.loading = false;
        },
        logout: (state) => {
            state.user = null;
            state.token = null;
        },
    },
});

export const { loginStart, loginSuccess, loginFailure, logout } =
    authSlice.actions;
export const selectAuth = (state: { auth: AuthData }) => state.auth;

export default authSlice.reducer;
