import { CircularProgress, Container } from "@mui/material";
import useAuth from "../hooks/useAuth";
import React from "react";

const withAuth = <P extends object>(Component: React.ComponentType<P>): React.FC<P> => {
    return function AuthHOC(props: P) {
        const { loggedIn, loading } = useAuth();
        if (loading) {
            return <CircularProgress />;
        }
        return loggedIn ? <Component {...props} /> : <Container />;
    };
};

export default withAuth;
