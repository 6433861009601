import { Box, Button, Container, Paper, Typography } from "@mui/material";
import React, { useRef } from "react";
import withAuth from "../components/withAuth";
import TableView from "../components/TableView";
import * as XLSX from "xlsx";
import SnackbarDisplay, {
    SnackbarDisplayOptions,
} from "../components/SnackbarDisplay";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/en-gb";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import axios from "axios";
import { Icon } from "@iconify/react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../redux/store";
import { useNavigate } from "react-router-dom";
import { logout } from "../redux/reducers/AuthSlice";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

const API_KEY = process.env.REACT_APP_ROBUSTDIGITAL_API_KEY;

interface UploadedFileRow {
    "Campaign Id": string;
    "Amount Spent": string;
    [key: string]: string;
}

const MenuPage = () => {
    const [open, setOpen] = React.useState<boolean>(false);

    const [snackbar, setSnackbar] = React.useState<SnackbarDisplayOptions>({
        open: false,
        message: "",
        color: "error",
    });
    const dispatch = useDispatch<AppDispatch>();

    const navigate = useNavigate();
    const formRef = useRef<HTMLFormElement | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);

    let tempDate = new Date();
    const [date, setDate] = React.useState<Dayjs | null>( // I had to put month first because of the way dayjs works, it will return the date in the format DD/MM/YYYY
        dayjs(
            `${
                tempDate.getMonth() + 1
            }/${tempDate.getDate()}/${tempDate.getFullYear()}`
        )
    );

    const ref = useRef<HTMLInputElement | null>(null);
    const activateFileUpload = () => {
        ref.current?.click();
    };
    const handleLogout = async () => {
        const response = await axios.get(
            "https://robust-digital.gosocialdev.eu/logout", { withCredentials: true }
        );
        if (response.status === 200) {
            dispatch(logout());
        }
        navigate("/");
    };

  interface CampaignData {
    snapchat: string;
    clickflare: string;
  }

  interface CorrectData {
    update: CampaignData[];
    insert: CampaignData[];
    delete: CampaignData[];
  }

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = async (e) => {
      const binaryStr = e.target?.result;
      const workbook = XLSX.read(binaryStr, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const data: UploadedFileRow[] = XLSX.utils.sheet_to_json(sheet);

      // Validate the CSV data
      if (
        data.every(
          (row) => !row["Campaign Id"] || !row["Amount Spent"]
        ) ||
        data.length === 0
      ) {
        setSnackbar({
          open: true,
          message: `Invalid file format`,
          color: "error",
        });
        console.log("Invalid file format");
        return;
      }

      // Prepare the CSV data for Clickflare
      let output = "campaign_id,cost\n";
      data.forEach((row: UploadedFileRow) => {
        const clickflareCampaignID = row["Campaign Name"].split(".")[1];
        const amountSpent = row["Amount Spent"];
        if (clickflareCampaignID !== undefined) {
          output += `${clickflareCampaignID},${amountSpent}\n`;
        }
      });

      console.log("Output: ", output);
      const csvBlob = new Blob([output], { type: "text/csv" });
      const csvFile = new File([csvBlob], "cost.csv", { type: "text/csv" });
      const formData = new FormData();
      formData.append("file", csvFile);

      // Send data to Clickflare
      try {
        const startDate = date ? dayjs(date.toDate()).format('YYYY-MM-DD 00:00:00') : '';
        const endDate = date ? dayjs(date.toDate()).format('YYYY-MM-DD 23:59:59') : '';
        const url = `https://public-api.clickflare.io/api/campaigns/manual/cost?startDate=${startDate}&endDate=${endDate}&timezone=Europe/Berlin`;
        const result = await axios.patch(url, formData, {
          headers: {
            "api-key": API_KEY,
            "Content-Type": "multipart/form-data",
          },
        });
        console.log('Result: ', result);
      } catch (error) {
        console.log('Error sending data to Clickflare: ', error);
      }

      // Fetch existing Snapchat IDs from the backend
      let existingSnapchatIds: string[] = [];
      try {
        const response = await axios.get("https://robust-digital.gosocialdev.eu/campaigns", { withCredentials: true });
        existingSnapchatIds = response.data.map((campaign: any) => campaign.snapchat);
      } catch (error) {
        console.log('Error fetching existing Snapchat IDs:', error);
      }

      const correctData: CorrectData = { update: [], insert: [], delete: [] };

      // Prepare the data for update or insert
      data.forEach((row: UploadedFileRow) => {
        const campaignId = row["Campaign Id"];
        const clickflare = row["Campaign Name"].split(".")[1];

        if (!campaignId || !clickflare) {
          console.log(`Skipping row due to missing campaignId or clickflare: ${JSON.stringify(row)}`);
          return;
        }

        // Determine the type of operation based on existingSnapchatIds
        if (existingSnapchatIds.includes(campaignId)) {
          correctData.update.push({
            snapchat: campaignId,
            clickflare
          });
        } else {
          correctData.insert.push({
            snapchat: campaignId,
            clickflare
          });
        }
      });

      console.log("Final correctData: ", correctData);

      // Send data to the backend
      try {
        await axios.post("https://robust-digital.gosocialdev.eu/update-campaigns", correctData, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        });
        console.log("Data sent successfully");
      } catch (error) {
        console.log('Error sending data to Robust Digital: ', error);
      }

      setSnackbar({
        open: true,
        message: `File is processed.`,
        color: "success",
      });
    };

    reader.readAsArrayBuffer(file as Blob);
    e.target.value = ""; // Clear the input value
  };


    return (
        <Container maxWidth="sm">
            <SnackbarDisplay
                options={snackbar}
                setOpen={(val: boolean) => {
                    setSnackbar({ ...snackbar, open: val });
                }}
            />
            <Box display={"none"}>
                <form ref={formRef}>
                    <input ref={inputRef} type="file" />
                    <button>Submit</button>
                </form>
            </Box>
            <Paper
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 6,
                    mt: 8,
                    py: 8,
                }}
                elevation={8}
            >
                <Typography fontWeight={"bold"}>
                    Robust Digital Clickflare Integration
                </Typography>
                <Box sx={{ alignSelf: "start", px: 7 }}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="en-gb"
                    >
                        <DemoContainer
                            components={["DatePicker", "DatePicker"]}
                        >
                            <DatePicker
                                label="Select Date"
                                disableFuture
                                value={date}
                                onChange={(newValue) => setDate(newValue)}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 4,
                        width: "80%",
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={activateFileUpload}
                    >
                        Daily Cost CSV Upload
                    </Button>
                    <input
                        type="file"
                        ref={ref}
                        style={{ display: "none" }}
                        accept=".csv,.xlsx"
                        onChange={handleFileUpload}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setOpen(true)}
                    >
                        Campaign List
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleLogout}
                    >
                        <Icon icon="material-symbols:logout" fontSize={22} />{" "}
                        Logout
                    </Button>
                </Box>
            </Paper>
            <TableView open={open} setOpen={setOpen} />
        </Container>
    );
};

export default withAuth(MenuPage);
