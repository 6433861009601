import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../redux/store";
import { loginSuccess, selectAuth } from "../redux/reducers/AuthSlice";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";

export default function useAuth() {
    const { token } = useSelector(selectAuth);
    const dispatch = useDispatch<AppDispatch>();

    const [nameCookie, tokenCookie] = [
        Cookies.get("user"),
        Cookies.get("token"),
    ];

    useEffect(() => {
        if (nameCookie === "emirhan" && tokenCookie === "neo") {
            console.log("for demo");
            dispatch(loginSuccess({ user: "emirhan", token: "neo" }));
        }
    }, [nameCookie, tokenCookie, dispatch]);

    const [loggedIn, setLoggedIn] = useState<boolean>(
        !!(token && token === "neo")
    );
    const [loading, setLoading] = useState<boolean>(true);

    const login = async (email: string, password: string): Promise<boolean> => {
        try {
            const res = await axios.post(
                "https://robust-digital.gosocialdev.eu/login",
                {
                    email,
                    password,
                },
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            if (res.status === 200) {
                dispatch(loginSuccess({ user: email, token: res.data.token }));
                setLoggedIn(true);
                setLoading(false);
                
                console.log("for demo set to the false");
                return true;
            } else {
                console.log("status failed", res.status);
                return false;
            }
        } catch (error) {
            console.log("error", error);
            return false;
        }
    };

    useEffect(() => {
        if (token !== null) {
            setLoggedIn(true);
        } else {
            setLoggedIn(false);
            console.log("for demo");
        }
        setLoading(false);
    }, [token]);

    return { login, loggedIn, loading };
}
