import { useState, useEffect } from "react";
import axios from "axios";
import { Row } from "../types";

export default function useCampaignData() {
    const [campaignData, setCampaignData] = useState<Row[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get("https://robust-digital.gosocialdev.eu/campaigns", {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const data = (await response.data) as Row[];
            console.log("Campaigns from DB: ", data);
            setCampaignData(data);
            setLoading(false);
        };
        fetchData();
    }, []);
    return { campaignData, loading };
}
