import {
    AppBar,
    Box,
    Dialog,
    IconButton,
    Slide,
    Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { Row } from "../types";
import useCampaignData from "../hooks/useCampaignData";
import DeleteConfirmationModal, { ModalDetails } from "./ModalConfirm";
import axios from "axios";
import { ObjectId } from "bson";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function TableView({
    open,
    setOpen,
}: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const { campaignData, loading } = useCampaignData();
    const [rowState, setRowState] = useState<Row[]>([]);
    const [modalDetails, setModalDetails] = useState<ModalDetails>({
        open: false,
        snapchat: "",
        clickflare: "",
        _id: "",
    });
    const unChangedRowState = useRef<Row[]>([]);
    const handleRowDelete = (_id: string) => {
        const updatedRows = rowState.filter((row) => row._id !== _id);
        setRowState(updatedRows);
    };
    useEffect(() => {
        if (!loading) {
            setRowState(campaignData);
            unChangedRowState.current = campaignData;
        }
    }, [loading, campaignData]);
    const columns: GridColDef<(typeof campaignData)[number]>[] = [
        {
            field: "snapchat",
            headerName: "Snapchat ID",
            flex: 1,
            minWidth: 200,
            editable: true,
        },
        {
            field: "clickflare",
            headerName: "Clickflare ID",
            flex: 1,
            minWidth: 200,
            editable: true,
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            sortable: false,
            width: 120,
            getActions: (params) => [
                <GridActionsCellItem
                    size="medium"
                    icon={<Icon icon="ic:baseline-delete-forever" />}
                    label="Delete"
                    onClick={() => {
                        if (
                            params.row.snapchat !== "" ||
                            params.row.clickflare !== ""
                        )
                            setModalDetails({ ...params.row, open: true });
                    }}
                />,
            ],
        },
    ];

    useEffect(() => {
        const add: Row = {
            _id: new ObjectId().toString(),
            snapchat: "",
            clickflare: "",
        };
        if (rowState.length === 0) {
            setRowState([add]);
            return;
        }
        const lastIndex = rowState[rowState.length - 1];
        if (lastIndex.snapchat !== "" || lastIndex.clickflare !== "") {
            setRowState((prev) => [...prev, add]);
        }
    }, [rowState]);

    const prepareData = () => {
        const rowStateClone: Row[] = rowState.filter((row) => {
            return row.snapchat !== "" && row.clickflare !== "";
        });
        const rowStateIDs = rowStateClone.map((row) => row._id);
        const unChangedRowStateIDs = unChangedRowState.current.map(
            (row) => row._id
        );
        const newItems = rowStateIDs.filter(
            (id) => !unChangedRowStateIDs.includes(id)
        );
        const deletedIDs = unChangedRowStateIDs.filter(
            (id) => !rowStateIDs.includes(id)
        );
        const updatedRows = rowStateClone.filter((row) => {
            const unChangedData = unChangedRowState.current.find(
                (temp) => temp._id === row._id
            );
            return (
                !newItems.includes(row._id) &&
                !deletedIDs.includes(row._id) &&
                rowStateIDs.includes(row._id) &&
                (unChangedData!.clickflare !== row.clickflare ||
                    unChangedData!.snapchat !== row.snapchat)
            );
        });

        const data = {
            insert: [],
            delete: [],
            update: [],
        } as any;

        if (newItems.length > 0) {
            data["insert"] = rowStateClone.filter((row) =>
                newItems.includes(row._id)
            );
        }
        if (deletedIDs.length > 0) {
            data["delete"] = unChangedRowState.current.filter((row) =>
                deletedIDs.includes(row._id)
            );
        }
        if (updatedRows.length > 0) {
            data["update"] = updatedRows;
        }
        console.log("Prepared Data: ", data);
        unChangedRowState.current = rowStateClone;
        setOpen(false);
        return data;
    };

    const sendData = async (data: {[key:string]:[]}) => {
      console.log("Sending data: ", data);

        const res = await axios.post("https://robust-digital.gosocialdev.eu/update-campaigns", data, {withCredentials: true, headers: {
            'Content-Type': 'application/json',
            
        }});
        if (res.status === 200) {
            console.log("Data sent successfully");
            console.log("sendData Response: ", res.data);


        } else {
            console.log("Error while sending data");
        }
    }

    return (
        <Dialog fullScreen open={open} TransitionComponent={Transition}>
            <DeleteConfirmationModal
                details={modalDetails}
                onClose={() => {
                    setModalDetails({ ...modalDetails, open: false });
                }}
                onConfirm={() => {
                    handleRowDelete(modalDetails._id);
                    setModalDetails({ ...modalDetails, open: false });
                }}
            />
            <AppBar
                sx={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingRight: 0,
                    height: 50,
                }}
            >
                <IconButton
                    sx={{
                        fontSize: 80,
                        width: 48,
                        height: 48,
                        color: "white",
                        fontWeight: "bold",
                    }}
                    onClick={() => {
                        const data = prepareData();
                        sendData(data);
                    }}
                >
                    <Icon icon="ic:round-close" />
                </IconButton>
                <Typography
                    sx={{
                        ml: 0,
                        fontSize: 24,
                        fontWeight: "bold",
                        textAlign: "center",
                        flexGrow: 1,
                    }}
                >
                    Campaigns
                </Typography>
                <div style={{ width: 48, padding: 0 }} />
            </AppBar>
            <Box sx={{ height: "full", width: "100%" }}>
                <DataGrid
                    rows={rowState}
                    columns={columns}
                    editMode="row"
                    getRowId={(row) => row._id}
                    initialState={{
                        pagination: {},
                    }}
                    processRowUpdate={(updatedRow, originalRow) => {
                        const updatedRows = rowState.map((row) => {
                            if (row._id === updatedRow._id) {
                                return updatedRow;
                            }
                            return row;
                        });

                        setRowState(updatedRows);
                        return updatedRow;
                    }}
                    disableColumnFilter
                    disableColumnMenu
                    disableColumnSorting
                    disableColumnSelector
                    disableColumnResize
                    checkboxSelection
                    disableRowSelectionOnClick
                />
            </Box>
        </Dialog>
    );
}
